var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",staticClass:"overflow-hidden",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('header',{staticClass:"d-flex justify-space-between align-center mb-2"},[_vm._t("title",null,{"title":_vm.title}),_c('v-checkbox',{staticClass:"ml-1 my-2 pt-0",attrs:{"ripple":false,"hide-details":""},model:{value:(_vm.localSettingsCurrency.autoRefillEnabled),callback:function ($$v) {_vm.$set(_vm.localSettingsCurrency, "autoRefillEnabled", $$v)},expression:"localSettingsCurrency.autoRefillEnabled"}})],2),_c('aside',[_c('FormField',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"))+" ")]},proxy:true}])},[_c('BaseDropDown',{attrs:{"value":_vm.selectedCurrency,"items":_vm.currencies,"menu-props":{ offsetY: true, closeOnClick: true },"outlined":"","dense":""},on:{"change":_vm.onChangeCurrency},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center text-no-wrap content-disabled"},[_c('BaseImg',{staticClass:"flex-shrink-0",attrs:{"src":_vm.currentCurrencyIcon,"contain":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.selectedCurrency)+", "+_vm._s(_vm.currentSymbolCurrency)+" ")])],1)]},proxy:true}])})],1),_c('FormField',{attrs:{"required":_vm.localSettingsCurrency.autoRefillEnabled,"disabled":!_vm.localSettingsCurrency.autoRefillEnabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.auto_refill.threshold"))+" ")]},proxy:true}])},[_c('v-text-field',{attrs:{"value":_vm.localSettingsCurrency.autoRefillEnabled
            ? _vm.localSettingsCurrency.autoRefillThreshold
            : '',"rules":_vm.localSettingsCurrency.autoRefillEnabled
            ? [
                _vm.fieldRules.required,
                _vm.fieldRules.positiveNumber,
                _vm.fieldRules.autoRefillThreshold,
              ]
            : undefined,"disabled":!_vm.localSettingsCurrency.autoRefillEnabled,"outlined":"","dense":"","inputmode":"decimal"},on:{"input":function($event){_vm.localSettingsCurrency.autoRefillThreshold = $event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center pr-1",style:({ paddingTop: '2px' })},[_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0",attrs:{"src":_vm.currentCurrencyIcon,"width":"20"}}),_c('span',{staticClass:"black--text ml-1 text-caption-1"},[_vm._v(" "+_vm._s(_vm.currentSymbolCurrency)+" ")])],1)]},proxy:true}])})],1),_c('FormField',{attrs:{"required":_vm.localSettingsCurrency.autoRefillEnabled,"disabled":!_vm.localSettingsCurrency.autoRefillEnabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.deposit_amount"))+" ")]},proxy:true}])},[_c('v-text-field',{attrs:{"value":_vm.localSettingsCurrency.autoRefillEnabled
            ? _vm.localSettingsCurrency.autoRefillAmount
            : '',"rules":_vm.localSettingsCurrency.autoRefillEnabled
            ? [
                _vm.fieldRules.required,
                _vm.fieldRules.positiveNumber,
                _vm.fieldRules.autoRefillAmount,
              ]
            : undefined,"disabled":!_vm.localSettingsCurrency.autoRefillEnabled,"outlined":"","dense":"","inputmode":"decimal"},on:{"input":function($event){_vm.localSettingsCurrency.autoRefillAmount = $event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center pr-1",style:({ paddingTop: '2px' })},[_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0",attrs:{"src":_vm.currentCurrencyIcon,"width":"20"}}),_c('span',{staticClass:"black--text ml-1 text-caption-1"},[_vm._v(" "+_vm._s(_vm.currentSymbolCurrency)+" ")])],1)]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"ripple":false,"block":"","type":"submit","color":"primary","height":"40"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.save"))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"ripple":false,"outlined":"","block":"","type":"button","color":"error","height":"40"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.close"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }