import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import {
  isEmpty,
  getSymbolCurrency,
  formatMoney,
  isPositiveNumber,
  findInObject,
  cloneStructured,
  forOwn,
  numberToFixed,
} from "@helpers";
import {
  MIN_AUTO_REFILL_THRESHOLD,
  MAX_AUTO_REFILL_THRESHOLD,
  MIN_AUTO_REFILL_AMOUNT,
  MAX_AUTO_REFILL_AMOUNT,
} from "@config/base";

import { VForm } from "@/types/vuetify";
import { Currency } from "@/types/currency";
import API from "@api";
import { TeamMember } from "@/types/team";
import { walletModule } from "@store/namespaces";
import { WalletGetters } from "@store/modules/wallet/types";

@Component({
  inheritAttrs: false,
})
export default class AutoRefillForm extends Vue {
  @walletModule.Getter("defaultWalletCurrency")
  private readonly defaultWalletCurrencyGetter!: WalletGetters["defaultWalletCurrency"];

  @Ref("form") private readonly formRef?: VForm;
  @Prop({ type: Object })
  private readonly settings?: TeamMember["autoRefillSettings"];
  @Prop({ type: Object })
  private readonly balance!: Record<Currency, number>;
  @Prop({ type: String, required: true }) private readonly email!: string;
  @Prop({ type: Array, required: true })
  private readonly currencies!: Currency[];

  private loading = false;

  private selectedCurrency = Currency.USD;
  private localSettings: TeamMember["autoRefillSettings"] = {};

  private get localSettingsCurrency() {
    return this.localSettings[this.selectedCurrency];
  }

  private get minAutoRefillAmount() {
    return MIN_AUTO_REFILL_AMOUNT;
  }

  private get maxAutoRefillAmount() {
    return Math.min(
      this.balance[this.selectedCurrency],
      MAX_AUTO_REFILL_AMOUNT
    );
  }

  private get title() {
    return this.$vuetify.lang.t("$vuetify.dashboard.auto_refill.title");
  }

  private get currentSymbolCurrency() {
    return getSymbolCurrency(this.selectedCurrency);
  }

  private get currentCurrencyIcon() {
    return require(`@/assets/img/currency/flags/${this.selectedCurrency}.svg`);
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
      autoRefillThreshold: (v: string) => {
        if (+v > MAX_AUTO_REFILL_THRESHOLD) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            formatMoney({
              value: MAX_AUTO_REFILL_THRESHOLD,
              currency: this.selectedCurrency,
            })
          );
        }

        if (+v < MIN_AUTO_REFILL_THRESHOLD) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: MIN_AUTO_REFILL_THRESHOLD,
              currency: this.selectedCurrency,
            })
          );
        }

        return true;
      },
      autoRefillAmount: (v: string) => {
        if (+v < this.minAutoRefillAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: this.minAutoRefillAmount,
              currency: this.selectedCurrency,
            })
          );
        }

        if (this.maxAutoRefillAmount < this.minAutoRefillAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.insufficient_balance_user",
            this.email
          );
        }

        if (+v > this.maxAutoRefillAmount) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            formatMoney({
              value: this.maxAutoRefillAmount,
              currency: this.selectedCurrency,
            })
          );
        }

        return true;
      },
    };
  }

  private onCancel() {
    this.$emit("cancel");
  }

  private onChangeCurrency(newCurrency: Currency) {
    const isValidForm = this.validateForm();

    if (!isValidForm) {
      const settingsCurrency =
        this.settings && this.settings[this.selectedCurrency];

      this.$set(this.localSettings, this.selectedCurrency, settingsCurrency);
    }

    this.selectedCurrency = newCurrency;
  }

  private validateForm() {
    return !!this.formRef?.validate();
  }

  private async onSubmitForm() {
    if (this.loading || !this.validateForm()) return;

    this.loading = true;

    const localSettings = cloneStructured(this.localSettings);

    forOwn(localSettings, (item) => {
      if (!item) return;

      item.autoRefillAmount = numberToFixed(item.autoRefillAmount, 3);
      item.autoRefillThreshold = numberToFixed(item.autoRefillThreshold, 3);
    });

    const settings = {
      ...this.settings,
      ...localSettings,
    };

    try {
      await API.team.updateTeamAutorefill({
        settings,
        email: this.email,
      });
      this.loading = false;
      this.$notify({
        type: "success",
        title: this.$vuetify.lang.t("$vuetify.info.changes_saved"),
      });
      this.$emit("success");
    } finally {
      this.loading = false;
    }
  }

  private created() {
    this.$watch(
      () => {
        const formCurrency =
          findInObject(this.settings, (item) => {
            return !!item?.autoRefillEnabled;
          })?.key || this.defaultWalletCurrencyGetter;

        return formCurrency;
      },
      (currency) => {
        this.selectedCurrency = currency;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => this.selectedCurrency,
      (currency) => {
        const settingsCurrency = this.settings && this.settings[currency];

        this.$set(this.localSettings, this.selectedCurrency, {
          ...settingsCurrency,
          autoRefillEnabled: settingsCurrency?.autoRefillEnabled ?? false,

          autoRefillAmount:
            settingsCurrency?.autoRefillAmount.toString() ??
            MIN_AUTO_REFILL_AMOUNT.toString(),

          autoRefillThreshold:
            settingsCurrency?.autoRefillThreshold.toString() ||
            MIN_AUTO_REFILL_THRESHOLD.toString(),
        });
      },
      {
        immediate: true,
      }
    );
  }
}
